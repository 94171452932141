<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="users-change-password">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('users.password')"
              v-model="editPassword"
              :error-messages="editPasswordErrors"
              @input="$v.editPassword.$touch()"
              @blur="$v.editPassword.$touch()"
              type="password"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('users.passwordRepeat')"
              v-model="editPasswordRepeat"
              :error-messages="editPasswordRepeatErrors"
              @input="$v.editPasswordRepeat.$touch()"
              @blur="$v.editPasswordRepeat.$touch()"
              type="password"
            >
            </v-text-field>
          </div>
        </v-form>
      </div>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="
            authCanByUserId('editOwn.users', this.objectId) ||
            authCan('editOthers.users')
          "
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { required, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "UsersEdit",
  mixins: [Permissions, ComponentStatus],
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    firstFormClick: true,
    changed: false,

    // Edit proxy form data
    editPassword: "",
    editPasswordRepeat: "",
  }),
  validations: {
    editPassword: {
      required,
      maxLength: maxLength(100),
    },
    editPasswordRepeat: {
      required,
      sameAsPassword: sameAs("editPassword"),
      maxLength: maxLength(100),
    },
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editOrganisationId() {
      return this.$store.getters["auth/getOrganisationId"];
    },
    editPasswordErrors() {
      const errors = [];
      if (!this.$v.editPassword.$dirty) return errors;
      if (!this.$v.editPassword.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editPassword.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editPasswordRepeatErrors() {
      const errors = [];
      if (!this.$v.editPasswordRepeat.$dirty) return errors;
      if (!this.$v.editPasswordRepeat.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editPasswordRepeat.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      if (!this.$v.editPasswordRepeat.sameAsPassword) {
        errors.push(this.$t("auth.errors.sameAsPassword"));
      }
      return errors;
    },
    // 2) When the user id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the user data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    user() {
      return this.$store.getters["users/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the user id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editUserId = this.objectId;
          this.$store
            .dispatch("users/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
  },
  created() {},
  methods: {
    inputChanged() {
      this.changed = true;
    },
    clickUpdate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        this.sendUpdate();
      }
    },
    sendUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editUserId,
        password: this.editPassword,
        passwordConfirmation: this.editPasswordRepeat,
      };
      this.$store
        .dispatch("users/changePassword", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
